<h3>Create Order</h3>

<form id="order-form" [formGroup]="orderForm" (ngSubmit)="onSubmit()">

  <div>
    <label for="serialno">
      Serial No.
    </label>
    <input id="serialno" type="text" formControlName="serialno">
  </div>

  <div>
    <label for="quantity">
      Quantity
    </label>
    <input id="quantity" type="text" formControlName="quantity">
  </div>

  <div>
    <label for="supplier">
      Supplier
    </label>
    <input id="supplier" type="text" formControlName="supplier">
  </div>

  <div>
    <label for="spec">
      Specification
    </label>
    <select id="spec" formControlName="spec">
      <option *ngFor="let document of documents" value="{{document.id}}">{{document.description}}</option>
    </select>
  </div>

  <button class="button" type="submit">Place Order</button>

</form>