import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { APIService } from '../myAPI.service';

@Component({
  selector: 'app-deliver',
  templateUrl: './deliver.component.html',
  styleUrls: ['./form.component.css']
})
export class DeliverComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  documents: Array<any>;
  subscription: Subscription;

  deliverForm = new FormGroup({
    wfItemId: new FormControl(''),
  });

  constructor(
    private apiService: APIService
  ) {
    console.log('DeliverComponent subscribing');
    this.subscription = apiService.loadModal$.subscribe(
      modal => {
        if (modal == 'app-delivery') {
          this.update();
        }
      }
    );
  }

  ngOnInit() {
    console.log('DeliverComponent loaded');
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  update() {
    this.apiService.getItems().subscribe((data: Array<any>) => {
      this.documents = [];

      // the bucket itself is private so we cannot access via the URL
      // 'https://scgbasestack-scgingest65903767-1t3axunlopahg.s3-eu-west-1.amazonaws.com/'
      data.forEach(document => {
        if (document.docMeta.state == 'CERTIFIED') {
          this.documents.push({
            id: document.docKey,
            serialno: document.docBody.serialno,
            quantity: document.docBody.quantity
          });
        }
      });

      let logArray = this.documents.map((obj) => { return obj.serialno });
      console.debug('DeliveryComponent got items:', logArray);
    });
  }

  onSubmit() {
    // ToDo: verify authorization against cognito login

    let wfItemId = this.deliverForm.value.wfItemId;

    // ToDo: give feedback on success as in, e.g., the upload component
    this.apiService.fireTransition('DELIVER', wfItemId, 'user2').then((res) => {
      console.log('DONE');

      let modalBody = document.getElementById('deliver-form');
      modalBody.innerHTML = `<p style="color: green; weight: bold">DONE</p>`;
    })
      .catch(
        (error) => {
          console.warn('FAILED: ' + JSON.stringify(error));

          let modalBody = document.getElementById('deliver-form');
          modalBody.innerHTML = `<p style="color: red; weight: bold">FAILED</p>`;
        }
      );

    console.info('Your deliver request has been submitted for wfItemId=', this.deliverForm.value.wfItemId);
  }
}