<div id="modal_1" class="mmodal mmodal-background hhidden">
    <div id="modal-body" class="mmodal-body" style="max-height:80%; overflow-y:scroll;">

        <!-- ToDo: add every new modal component here -->
        <app-documents></app-documents>
        <app-items></app-items>

        <app-upload></app-upload>

        <app-order></app-order>
        <app-produce></app-produce>
        <app-certify></app-certify>
        <app-deliver></app-deliver>
        <app-confirm></app-confirm>

        <button (click)="closeDialog()" style="float:right">Close Dialog</button>
    </div>
</div>