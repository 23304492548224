import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ModalComponent } from './app.modal';
import { ItemsComponent } from './forms/items.component';
import { DocumentsComponent } from './forms/documents.component';
import { UploadComponent } from './forms/upload.component';
import { OrderComponent } from './forms/order.component';
import { ProduceComponent } from './forms/produce.component';
import { CertifyComponent } from './forms/certify.component';
import { DeliverComponent } from './forms/deliver.component';
import { ConfirmComponent } from './forms/confirm.component';
import { APIService } from './myAPI.service';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { AuthInterceptor } from './auth.interceptor';


/* Configure Amplify resources */
Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent, ModalComponent, ItemsComponent,
    UploadComponent, OrderComponent, ProduceComponent, CertifyComponent,
    DeliverComponent, ConfirmComponent, DocumentsComponent
  ],
  imports: [
    AmplifyUIAngularModule, BrowserModule, HttpClientModule, FormsModule, ReactiveFormsModule
  ],
  providers: [
    APIService, HttpClient, ModalComponent,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
