import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { APIService } from '../myAPI.service';
import { OrderModel } from '../models/order.model'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./form.component.css']
})
export class OrderComponent implements OnInit, OnDestroy {
  order: OrderModel;
  documents: Array<any>;
  subscription: Subscription;

  orderForm = new FormGroup({
    serialno: new FormControl(''),
    quantity: new FormControl('', [Validators.required, Validators.pattern("[0-9]+")]),
    supplier: new FormControl(''),
    spec: new FormControl(''),
  });

  constructor(
    private apiService: APIService
  ) {
    console.log('OrderComponent subscribing');
    this.subscription = apiService.loadModal$.subscribe(
      modal => {
        if (modal == 'app-order') {
          this.update();
        }
      }
    );
  }

  ngOnInit() {
    console.log('OrderComponent loaded');
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  update() {
    this.apiService.getDocuments().subscribe((data: Array<any>) => {

      this.documents = [];

      // the bucket itself is private so we cannot access via the URL
      // 'https://scgbasestack-scgingest65903767-1t3axunlopahg.s3-eu-west-1.amazonaws.com/'
      data.forEach(document => {
        this.documents.push({
          id: document.docKey,
          description: document.docBody.description,
          author: document.docBody.author,
          url: 'https://nl0i0x61yc.execute-api.eu-west-1.amazonaws.com/prod/s3objects' + document.docBody.url,
        });
      });

      let logArray = this.documents.map((obj) => { return obj.description });
      console.debug('OrderComponent got documents:', logArray);
    });
  }

  onSubmit() {
    let order = this.orderForm.value;
    order.quantity = parseInt(order.quantity);

    // ToDo: give feedback on success as in, e.g., the upload component
    this.apiService.putItem(order).then((res) => {
      console.log('DONE');

      let modalBody = document.getElementById('order-form');
      modalBody.innerHTML = `<p style="color: green; weight: bold">DONE</p>`;
    })
      .catch(
        (error) => {
          console.warn('FAILED: ' + JSON.stringify(error));

          let modalBody = document.getElementById('order-form');
          modalBody.innerHTML = `<p style="color: red; weight: bold">FAILED</p>`;
        }
      );

    console.info('Your order has been submitted', this.orderForm.value);
  }
}