import { Component } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';

import { ModalComponent } from './app.modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  org: string;
  user: any;

  constructor(private modalComponent: ModalComponent) {
    // listen to authentication events    

    // ToDo: replace this by directly hooking into the SignIn() method
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('Authentication:', event, data);
          localStorage.setItem('appWidth', '900');

          Auth.currentAuthenticatedUser().then((user) => {
            localStorage.setItem('idToken', user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('idTokenExpiry', user.signInUserSession.idToken.payload.exp);
            localStorage.setItem('authenticatedUser', user.attributes.email);
            this.mapUserForTemplate(user.attributes.email);

            // ToDo: implement a mechanism that enforces re-login or token renewal after timeout
          }).catch((err) => {
            console.warn('No user authenticated yet!');
          });
          window.location.reload();
          break;
        case 'signOut':
          console.log('Authentication:', event);
          // unset all user state
          break;
      }
    });

    this.user = localStorage.getItem('authenticatedUser');
    this.org = localStorage.getItem('authenticatedOrg');
  }

  // ToDo: also adapt the "dashboard" headline

  mapUserForTemplate(userEmail) {
    let domain = (userEmail.split('@'))[1];
    console.log('Setting session for user from domain ' + domain);

    switch (domain) {
      case 'octank.com':
        localStorage.setItem('templateColor', '#1976d2');
        localStorage.setItem('authenticatedOrg', 'Octank');
        break;
      case 'auditor.com':
        localStorage.setItem('templateColor', 'lightcoral');
        localStorage.setItem('authenticatedOrg', 'Auditor');
        break;
      case 'supplier.com':
        localStorage.setItem('templateColor', 'gold');
        localStorage.setItem('authenticatedOrg', 'Supplier');
        break;
      default:
        console.warn('Could not match email ' + userEmail + ' to any organisation.');
    }
  }

  getAppWidth() {
    // ToDo: this is a hack to reset width of the main component after login
    let appWidth = localStorage.getItem('appWidth');
    if (!appWidth) { appWidth = "460" }
    return appWidth;
  }

  getTemplateColor() {
    let templateColor = localStorage.getItem('templateColor');
    return templateColor;
  }

  async signOut() {
    try {
      localStorage.setItem('appWidth', '460');
      // ToDo: those might be redundant as CognitoIdentityServiceProvider also places all the information into localStorage
      localStorage.removeItem('idToken');
      localStorage.removeItem('idTokenExpiry');
      localStorage.removeItem('authenticatedUser');
      localStorage.removeItem('authenticatedOrg');
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  showAddDocument() {
    this.modalComponent.showDialog('app-upload');
  }

  showDocuments() {
    this.modalComponent.showDialog('app-documents');
  }

  showItems() {
    this.modalComponent.showDialog('app-items');
  }

  showOrderDialog() {
    this.modalComponent.showDialog('app-order');
  }

  showProduceDialog() {
    this.modalComponent.showDialog('app-produce');
  }

  showCertifyDialog() {
    this.modalComponent.showDialog('app-certify');
  }

  showDeliverDialog() {
    this.modalComponent.showDialog('app-deliver');
  }

  showConfirmDialog() {
    this.modalComponent.showDialog('app-confirm');
  }

}
