import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { APIService } from '../myAPI.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./form.component.css']
})
export class ItemsComponent implements OnInit {
  items: Array<any>;
  subscription: Subscription;
  wfDocsMap = [];

  constructor(
    private apiService: APIService
  ) {
    console.log('ItemsComponent subscribing');
    this.subscription = apiService.loadModal$.subscribe(
      modal => {
        if (modal == 'app-items') {
          this.update();
        }
      }
    );
  }

  ngOnInit() {
    console.log('ItemsComponent loaded');

    // we only load data once the modal gets displayed
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  onSubmit() {
    // Process checkout data here
    //this.items = this.cartService.clearCart();
    //this.orderForm.reset();

    console.warn('This should never be called!');
  }

  getDocument(objKey: string) {
    // ToDo: scroll down to document container

    this.apiService.getS3Object(objKey).then(
      (res) => {
        document.getElementById("items-document-container").innerText = res;
      }).catch(
        (error) => {
          console.error('Got HTTP error', error.status);
          document.getElementById("items-document-container").innerText = "NOT FOUND";
        }).finally(() => {
          document.getElementById("items-document-container").setAttribute("style", "margin: 10px; padding: 10px; border: 1px solid black; display:block;");
          let modalBody = document.getElementById("modal-body");
          modalBody.scrollTop = modalBody.scrollHeight;
        });

  }

  update() {
    // ToDo: fire those two request in parallel to speed up loading
    this.apiService.getDocuments().subscribe((wfDocuments: Array<any>) => {
      this.wfDocsMap = []

      wfDocuments.forEach(wfDocument => {
        this.wfDocsMap[wfDocument.docKey] = wfDocument.docBody;
      });

      this.apiService.getItems().subscribe((wfItems: Array<any>) => {
        this.items = [];

        // the bucket itself is private so we cannot access via the URL
        // 'https://scgbasestack-scgingest65903767-1t3axunlopahg.s3-eu-west-1.amazonaws.com/'
        wfItems.forEach(wfItem => {
          let desc, url;
          if (this.wfDocsMap[wfItem.docBody.spec]) {
            desc = this.wfDocsMap[wfItem.docBody.spec].description;
            url = this.wfDocsMap[wfItem.docBody.spec].url;
          }

          this.items.push({
            serialno: wfItem.docBody.serialno,
            quantity: wfItem.docBody.quantity,
            supplier: wfItem.docBody.supplier,
            spec: desc,
            spec_url: url,
            state: wfItem.docMeta.state,
            history: wfItem.docMeta.history.map((histitem) => {
              return {
                newState: histitem.newState,
                timestamp: (new Date(histitem.timestamp * 1000)).toLocaleString("en-GB")
              };
            })
          });
        });

        let logArray = this.items.map((obj) => { return obj.serialno });
        console.debug('ItemsComponent got items:', logArray);
      });

    });
  }
}