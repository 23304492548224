<h3>Produce Order</h3>

<form id="certify-form" [formGroup]="certifyForm" (ngSubmit)="onSubmit()">

  <div>
    <label for="wfItemId">
      Orders
    </label>
    <select id="wfItemId" formControlName="wfItemId">
      <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.quantity }} x {{ document.serialno }}</option>
    </select>
  </div>

  <button class="button" type="button" (click)="certify()">Certify Order</button> <br />
  
  <button class="button" type="submit" style="background-color: darkred;">Reject Order</button>

</form>