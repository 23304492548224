/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "WidgetService",
            "endpoint": "https://kppoxacnde.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6chtns2ixnehnboo6oazqalbkm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-oifmgzoelzbj5pcneldmnh3usy",
    "aws_cognito_identity_pool_id": "eu-west-1:f44c8937-f0ad-4134-8547-2b39ca98fe6b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_aiur6rwti",
    "aws_user_pools_web_client_id": "5nkdidio0l04mo3ik9ksda5k70",
    "oauth": {}
};


export default awsmobile;
