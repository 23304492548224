import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { APIService } from '../myAPI.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./form.component.css']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  documents: Array<any>;
  subscription: Subscription;

  constructor(
    private apiService: APIService
  ) {
    console.log('DocumentsComponent subscribing');

    // ToDo: this gets subscribed thrice!!!

    this.subscription = apiService.loadModal$.subscribe(
      modal => {
        if (modal == 'app-documents') {
          this.update();
        }
      }
    );
  }

  ngOnInit() {
    console.log('DocumentsComponent loaded');
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  onSubmit() {
    console.warn('This should never be called!');
  }

  getDocument(objKey: string) {
    this.apiService.getS3Object(objKey).then(
      (res) => {
        document.getElementById("documents-document-container").innerText = res;
      }).catch(
        (error) => {
          console.error('Got HTTP error', error.status);
          document.getElementById("documents-document-container").innerText = "NOT FOUND";
        })
      .finally(() => {
        document.getElementById("documents-document-container").setAttribute("style", "margin: 10px; padding: 10px; border: 1px solid black; display:block;");
        let modalBody = document.getElementById("modal-body");
        modalBody.scrollTop = modalBody.scrollHeight;
      });

  }

  update() {
    this.apiService.getDocuments().subscribe(
      (data: Array<any>) => {
        this.documents = [];

        // the bucket itself is private so we cannot access via the URL
        // 'https://scgbasestack-scgingest65903767-1t3axunlopahg.s3-eu-west-1.amazonaws.com/'
        data.forEach(document => {
          let documentDate = '';
          if (document.docBody.created_at) {
            documentDate = (new Date(document.docBody.created_at)).toLocaleString("en-GB");
          }

          this.documents.push({
            description: document.docBody.description,
            author: document.docBody.author,
            url: document.docBody.url,
            created_at: documentDate
          });
        });

        let logArray = this.documents.map((obj) => { return obj.description });
        console.debug('DocumentsComponent got wfDocuments:', logArray);
      },
      err => {
        console.warn('Got HTTP error', err.status);
      }
    );
  }
}