<h3>Upload Document</h3>

<h4>Step 1: Select Document</h4>

<form id="document-upload-form">
    <input type="file" id="file" ng-model="model.file" (change)="addDocument($event)" />
</form>
<div id="document-upload-result"></div>

<h4>Step 2: Set Document Metadata</h4>

<form id="document-anchor-form" [formGroup]="uploadForm" (ngSubmit)="onSubmit()">

  <div>
    <label for="description">
      Description
    </label>
    <input type="text" id="description" formControlName="description"/>
  </div>

  <div>
    <label for="author">
      Author
    </label>
    <input id="author" type="text" formControlName="author">
  </div>

  <button class="button" type="submit">Anchor Document</button>
</form>
<div id="document-anchor-result"></div>