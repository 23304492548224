<h3>Confirm Order Delivery</h3>

<form id="confirm-form" [formGroup]="confirmForm" (ngSubmit)="onSubmit()">

  <div>
    <label for="wfItemId">
      Orders
    </label>
    <select id="wfItemId" formControlName="wfItemId">
      <option *ngFor="let document of documents" value="{{ document.id }}">{{ document.quantity }} x {{ document.serialno }}</option>
    </select>
  </div>

  <button class="button" type="submit">Confirm Order</button>

</form>