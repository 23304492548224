<h3>List of Items</h3>

<table width="100%">
    <thead style="background-color: darkgray; font-weight: bold;">
      <td width="20%">SerialNo</td>
      <td>Quantity</td>
      <td width="18%">Supplier</td>
      <td>Specification</td>
      <td>STATUS</td>
      <td width="30%">History</td>
    </thead>
    <tbody>
    <tr *ngFor="let item of items">
      <td style="vertical-align: top;"><strong>{{ item.serialno }}</strong></td>
      <td style="vertical-align: top;">{{ item.quantity }}</td>
      <td style="vertical-align: top;">{{ item.supplier }}</td>
      <td style="vertical-align: top;"><a style="font-weight: bold; color:#125699" (click)="getDocument(item.spec_url)">{{ item.spec }}</a></td>
      <td style="vertical-align: top;">{{ item.state }} </td>
      <td style="vertical-align: top;"><ul style="margin: 0px; font-size: 0.8em; padding-left:20px">
        <li *ngFor="let histitem of item.history">{{ histitem.newState }} : {{ histitem.timestamp }}</li>
      </ul></td>
    </tr></tbody>
</table>

<div id="items-document-container" style="margin: 10px; padding: 10px; border: 1px solid black; display:none; "></div>