import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { APIService } from '../myAPI.service';

@Component({
  selector: 'app-certify',
  templateUrl: './certify.component.html',
  styleUrls: ['./form.component.css']
})
export class CertifyComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  documents: Array<any>;
  doReject: boolean = true;
  subscription: Subscription;

  certifyForm = new FormGroup({
    wfItemId: new FormControl(''),
    reject: new FormControl('')
  });

  constructor(
    private apiService: APIService
  ) {
    console.log('CertifyComponent subscribing');
    this.subscription = apiService.loadModal$.subscribe(
      modal => {
        if (modal == 'app-certify') {
          this.update();
        }
      }
    );
  }

  ngOnInit() {
    console.log('CertifyComponent loaded');
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  update() {
    this.apiService.getItems().subscribe((data: Array<any>) => {
      this.documents = [];

      // the bucket itself is private so we cannot access via the URL
      // 'https://scgbasestack-scgingest65903767-1t3axunlopahg.s3-eu-west-1.amazonaws.com/'
      data.forEach(document => {
        if (document.docMeta.state == 'PRODUCED') {
          this.documents.push({
            id: document.docKey,
            serialno: document.docBody.serialno,
            quantity: document.docBody.quantity
          });
        }
      });

      let logArray = this.documents.map((obj) => { return obj.serialno });
      console.debug('CertifyComponent got items:', logArray);
    });
  }

  certify() {
    console.log('certify triggered');
    this.doReject = false;
    this.onSubmit();
  }

  onSubmit() {
    // ToDo: verify authorization against cognito login!

    let wfItemId = this.certifyForm.value.wfItemId;

    console.log("doReject: " + this.doReject);

    if (this.doReject) {
      this.apiService.fireTransition('REJECT', wfItemId, 'user3').then((_) => {
        console.log('DONE: Reject');

        let modalBody = document.getElementById('certify-form');
        modalBody.innerHTML = `<p style="color: green; weight: bold">DONE</p>`;
      })
        .catch(
          (error) => {
            console.warn('FAILED: ' + JSON.stringify(error));

            let modalBody = document.getElementById('certify-form');
            modalBody.innerHTML = `<p style="color: red; weight: bold">FAILED</p>`;
          }
        );
    } else {

      // ToDo: give feedback on success as in, e.g., the upload component
      this.apiService.fireTransition('CERTIFY', wfItemId, 'user3').then((_) => {
        console.log('DONE: Certify');

        let modalBody = document.getElementById('certify-form');
        modalBody.innerHTML = `<p style="color: green; weight: bold">DONE</p>`;
      })
        .catch(
          (error) => {
            console.warn('FAILED: ' + JSON.stringify(error));

            let modalBody = document.getElementById('certify-form');
            modalBody.innerHTML = `<p style="color: red; weight: bold">FAILED</p>`;
          }
        );
    }

    console.info('Your certify request has been submitted for wfItemId=' + this.certifyForm.value.wfItemId);
  }
}