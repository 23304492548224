import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { APIService } from '../myAPI.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./form.component.css']
})
export class UploadComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  subscription: Subscription;
  file: any;

  uploadForm = new FormGroup({
    description: new FormControl(''),
    author: new FormControl(''),
  });

  constructor(
    private apiService: APIService
  ) {
    console.log('UploadComponent subscribing');
    this.subscription = apiService.loadModal$.subscribe(
      modal => {
        if (modal == 'app-upload') {
          this.update();
        }
      }
    );
  }

  ngOnInit() {
    console.log('UploadComponent loaded');
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  update() {
    // reload modal to restore form fields after previous upload

    let modalUploadForm = document.getElementById('document-upload-form');
    let modalUploadResult = document.getElementById('document-upload-result');
    modalUploadForm.setAttribute('style', 'display:block');
    modalUploadResult.setAttribute('style', 'display:none');
    modalUploadResult.innerHTML = '';
    let modalAnchorForm = document.getElementById('document-anchor-form');
    let modalAnchorResult = document.getElementById('document-anchor-result');
    modalAnchorForm.setAttribute('style', 'display:block');
    modalAnchorResult.setAttribute('style', 'display:none');
    modalAnchorResult.innerHTML = '';
  }

  onSubmit() {
    // document gets automatically uploaded via the event in 'addDocument' in step 1

    // ToDo: replace the url below with the actual configured bucket!

    if (!this.file) {
      // "Anchor Document" got clicked before any file has been uploaded successfully
      console.log('FAILED: file missing');

      let modalAnchorForm = document.getElementById('document-anchor-form');
      let modalAnchorResult = document.getElementById('document-anchor-result');
      modalAnchorForm.setAttribute('style', 'display:none');
      modalAnchorResult.setAttribute('style', 'display:block');
      modalAnchorResult.innerHTML = `<p style="color: red; weight: bold">FAILED</p>`;

      return;
    }

    let wfDocument = this.uploadForm.value;
    wfDocument.created_at = Date.now();
    // ToDo: maybe omit the S3 bucket name?
    wfDocument.url = "/supplychainguardian-lock/" + this.file.filePath;

    console.log('Got document:', wfDocument);

    // ToDo: give feedback on success as in, e.g., the upload component
    this.apiService.putDocument({key: 'DOC_' + Date.now(), body: wfDocument}).then((res) => {
      console.log('DONE');

      let modalAnchorForm = document.getElementById('document-anchor-form');
      let modalAnchorResult = document.getElementById('document-anchor-result');
      modalAnchorForm.setAttribute('style', 'display:none');
      modalAnchorResult.setAttribute('style', 'display:block');
      modalAnchorResult.innerHTML = `<p style="color: green; weight: bold">SUCCESS</p>`;
    })
      .catch(
        (error) => {
          console.warn('FAILED: ' + JSON.stringify(error));

          let modalAnchorForm = document.getElementById('document-anchor-form');
          let modalAnchorResult = document.getElementById('document-anchor-result');
          modalAnchorForm.setAttribute('style', 'display:none');
          modalAnchorResult.setAttribute('style', 'display:block');
          modalAnchorResult.innerHTML = `<p style="color: red; weight: bold">FAILED</p>`;
        }
      );

    console.info('Your document has been submitted', this.uploadForm.value);
  }

  addDocument(event) {
    var model = event.target;
    var file: File = model.files[0];

    console.log('addDocument');

    this.readUploadedFileAsText(file).then((result: string) => {

      console.log(result);

      this.apiService.putS3Object(result).then((body) => {
        console.log('DONE: S3 upload');
        
        this.file = body;
        console.log(this.file.filePath);
        
        let modalUploadForm = document.getElementById('document-upload-form');
        let modalUploadResult = document.getElementById('document-upload-result');
        modalUploadForm.setAttribute('style', 'display:none');
        modalUploadResult.setAttribute('style', 'display:block');
        modalUploadResult.innerHTML = `<p style="color: green; weight: bold">SUCCESS</p>`;
      }).catch((error) => {
        console.warn('FAILED:' + JSON.stringify(error));

        let modalUploadForm = document.getElementById('document-upload-form');
        let modalUploadResult = document.getElementById('document-upload-result');
        modalUploadForm.setAttribute('style', 'display:none');
        modalUploadResult.setAttribute('style', 'display:block');
        modalUploadResult.innerHTML = `<p style="color: red; weight: bold">FAILED</p>`;
      });

    }).catch((err) => {
      console.error(err);
    })
  }

  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };
}