<h3>List of Documents</h3>

<table width="100%">
  <thead style="background-color: darkgray; font-weight: bold;">
    <td>Author</td>
    <td>Document</td>
    <td>Timestamp</td>
  </thead>
    <tbody>
    <tr *ngFor="let document of documents">
      <td width="50%"><strong>{{ document.author }}</strong></td>
      <td><a style="font-weight: bold; color:#125699" (click)="getDocument(document.url)">{{ document.description}}</a></td>
      <td>{{ document.created_at }}</td>
    </tr></tbody>
</table>

<div id="documents-document-container" style="margin: 10px; padding: 10px; border: 1px solid black; display:none; "></div>