import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject }    from 'rxjs';


@Injectable()
export class APIService {
    apiGatewayEndpoint = 'https://nl0i0x61yc.execute-api.eu-west-1.amazonaws.com/prod';
    currentModal: string;

    constructor(private httpClient: HttpClient) { }

    private loadModalSource = new BehaviorSubject<string>(undefined);

    // we only handle text files!
    putS3Object(body: string) {
        // ToDo: how do we ensure that this lambda uses the same S3 bucket we are referencing in the frontend?
        return this.httpClient.put(this.apiGatewayEndpoint + '/documents', body).toPromise();
    }

    getS3Object(objectKey: string) {
        let objectPath = objectKey.split('/');
        // ToDo: remove the second condition once we have removed all items with the typo...
        if (objectPath[1] === 'supplychainguardian-lock' || objectPath[1] === 'supplychaingurdian-lock') {
            objectKey = objectPath[2]; 
            // the Lambda does not expect the bucket name as prefix as of now
        }
        return this.httpClient.get(this.apiGatewayEndpoint + '/s3objects/' + objectKey, {responseType: 'text'}).toPromise();
    }

    async putDocument(document: any) {
        return this.httpClient.put(this.apiGatewayEndpoint + '/documents/' + document.key, document.body).toPromise();
    }

    getDocuments() {
        return this.httpClient.get(this.apiGatewayEndpoint + '/documents');
    }

    getItems() {
        return this.httpClient.get(this.apiGatewayEndpoint + '/items');
    }

    async putItem(order: any) {
        console.log(order);
        const randomId = 'PO_' + Date.now();

        console.log('PUT to /items/<itemId>');
        await this.httpClient.put(this.apiGatewayEndpoint + '/items/' + randomId, order).toPromise();

        console.log('POST to /transitions/ORDER');
        return this.httpClient.post(this.apiGatewayEndpoint + '/transitions/ORDER', {
                "wfItemId": randomId,
                "requestUser": "user1"
            }).toPromise();        
    }

    // ToDo: set the proper (authenticated) user here!
    fireTransition(wfTransitionId, wfItemId, requestUser) {
        return this.httpClient.post(this.apiGatewayEndpoint + '/transitions/' + wfTransitionId, {
            "wfItemId": wfItemId,
            "requestUser": requestUser
        }).toPromise();
    }

    loadModal$ = this.loadModalSource.asObservable();

    setModal(modal) {
        this.loadModalSource.next(modal);
        this.currentModal = modal;
    }

    getModal() {
        return this.currentModal;
    }

    // ToDo: add methods for the state and transition resources
}