import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { APIService } from './myAPI.service';

@Component({
    selector: 'app-showmodal',
    templateUrl: './app.modal.html',
    styleUrls: ['./app.modal.css']
})
export class ModalComponent implements OnInit {
    @Output() updated = new EventEmitter<string>();

    constructor(
        private apiService: APIService) { }

    ngOnInit() {
        console.log('ModalComponent loaded');

        // we explicitly hide every content block s.t. a full page reload does not break things
        document.querySelector('app-documents').setAttribute('style', 'display:none');
        document.querySelector('app-items').setAttribute('style', 'display:none');

        document.querySelector('app-upload').setAttribute('style', 'display:none');

        document.querySelector('app-order').setAttribute('style', 'display:none');
        document.querySelector('app-produce').setAttribute('style', 'display:none');
        document.querySelector('app-certify').setAttribute('style', 'display:none');
        document.querySelector('app-deliver').setAttribute('style', 'display:none');
        document.querySelector('app-confirm').setAttribute('style', 'display:none');
        // ToDo: add every new modal dialog here
    }

    showDialog(modal) {
        console.log('Show modal \'' + modal + '\'');
        let modal_t = document.getElementById('modal_1');
        modal_t.classList.remove('hhidden');
        modal_t.classList.add('sshow');
        document.querySelector(modal).setAttribute('style', 'display:block');
        
        this.apiService.setModal(modal);

        // ToDo: this should trigger a reload of the modal content, e.g., the document list!
    }

    closeDialog() {
        let currentModal = this.apiService.getModal();

        console.log('Close modal \'' + currentModal + '\'');
        let modal_t = document.getElementById('modal_1');
        modal_t.classList.remove('sshow');
        modal_t.classList.add('hhidden');

        document.querySelector(currentModal).setAttribute('style', 'display:none');
    }
}